<template>
<div>
    <!-- Navbar section starts -->

    <loader v-if="isLoading" class="loaderbg"></loader>

    <div class="overflow-y-hidden mob-withLoginScroll">
        <div class="row align-items-center navbar-desk" id="navbar-sec">
            <div class="col-md-8 menu_bar_links p-0">
                <a @click="liveCompitations" class="cursor-pointer">
                    <img :src="urlLink + 'images/logo_new/slogan/raffolux-slogan-yellow.svg'" alt="" class="logo-img" />
                </a>
                <a @click="liveCompitations" class="allCompetitionsMargin">
                    <h5 class="competion-link">All competitions</h5>
                </a>
                <a @click="winnersPage" class="winnersLinkMargin">
                    <h5 class="winner-link">Winners</h5>
                </a>
                <a @click="myRafflesPage" class="winnersLinkMargin">
                    <h5 class="winner-link">My tickets</h5>
                </a>

            </div>
            <div class="col-md-4 text-end d-flex align-items-center justify-content-end">
                <div @click="cartPage" class="position-relative">
                    <img :src="s3Url + '/cartIcon.svg'" class="home-cart-icon" alt="">
                    <span v-if="getcartCount > 0" class="cart-badge">{{
            getcartCount
          }}</span>
                </div>
                <div class="menuBarPointsBg" @click="go_to_pointstore">
                    <div class="">
                        <h4 class="pointsUserName">{{ capitalizeFirstLetter(firstName) }}</h4>
                        <p class="menubarPoints">
                            <img src="https://static.raffolux.com/static/website/images/favicon/favicon-32x32.png" alt="" class="menuBarPointsRaffoluxIcon">
                            {{ parseFloat(pointBalance.balance) }} RP
                        </p>
                    </div>
                    <div class="">
                        <img src="https://static.raffolux.com/static/website/redev_images/profile.svg" alt="" class="menuBarPointsImg">
                    </div>
                </div>
                <div class="menuBarOffCanvasImg" @click="my_credit(userSession)">
                    <img :src="`${s3Url}/open-menu.svg`" alt="" class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" />
                </div>
            </div>
        </div>

        <div class="row align-items-center navbar-mb" id="navbar-sec">
            <div class="col-4" @click="my_credit(userSession)">
                <img :src="`${s3Url}/open-menu.svg`" alt="" class="btn mb-offCanvasImg" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" />
            </div>
            <div class="col-4 text-center">
                <a @click="liveCompitations">
                    <img :src="s3Url + '/raffolux-slogan-yellow_navBar.png'" alt="" class="logo-img" />
                </a>
            </div>
            <div class="col-4 text-end">
                <div class="d-flex justify-content-around align-items-center">
                    <div class="menuBarMobPoints" @click="go_to_pointstore">
                        <img src="https://static.raffolux.com/static/website/images/favicon/favicon-32x32.png" alt="" class="mb-raffoluxPointsImg">
                        {{ parseFloat(pointBalance.balance) }}
                    </div>
                    <div @click="cartPage" class="position-relative">
                        <img :src="s3Url + '/cartIcon.svg'" class="mb-cart-icon" alt="">
                        <span v-if="getcartCount > 0" class="mb-cart-badge">{{
            getcartCount
          }}</span>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <!-- Navbar section ends -->

    <!-- Sidebar section starts -->
    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="user_sec">
            <div class="offcanvas-header justify-content-end p-0">
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
                    <i class="fa-solid fa-xmark"></i>
                </button>
            </div>
            <h3 class="user-txt">Hi {{ capitalizeFirstLetter(firstName) }},</h3>
            <p class="user_subTxt">
                You have tickets in <span @click="myRafflesPage">{{activeRafflesCount}} {{ activeRafflesCount <= 1 ? 'active raffle' : 'active raffles' }}</span>
            </p>

            <div class="position-relative menuWinnerBtn">

                <button v-if="prizeClaimCount > 0" type="button" @click="prizeClaimPage" class="btn winner-btn">
                    YOU'RE A WINNER

                </button>
                <div v-if="prizeClaimCount > 0" class=" confettiDisplay" id="winnerConfetti" @click="prizeClaimPage">
                    <img class="w-100" alt="" src="https://raffolux-static.s3-eu-west-2.amazonaws.com/static/website/images/you_are_winner.gif">
                </div>
                <span v-if="prizeClaimCount > 0" class="prize-badge">{{
            prizeClaimCount
          }}</span>
            </div>
        </div>
        <div class="offcanvas-body">
            <div class="myPoints-sec">
                <h4 class="main-hdr mt-0">MY POINTS</h4>
                <h3 class="d-flex align-items-center mt-3">
                    You have<span class="d-flex points_value align-items-center">
                        <img :src="urlLink + 'images/favicon/favicon-32x32.png'" alt="" class="points-img" />
                        <h5 class="mb-0">{{ parseFloat(pointBalance.balance) }}</h5>
                    </span>{{ parseFloat(pointBalance.balance) == 1 ? 'point' : 'points' }}
                </h3>
                <p class="points-subTxt mt-3">
                    Use your Raffolux points to redeem prizes in the
                    <a @click="go_to_pointstore" class="store_link">Store</a>
                </p>
            </div>
            <div class="menu-section">
                <h3 class="main-hdr">MENU</h3>
                <div class="menu-card" @click="homePage">
                    <span class="d-flex align-items-center">
                        <i class="fa-light fa-house icon-size"></i>

                        <h3 class="mb-0">Home</h3>
                    </span>
                </div>
                <div class="menu-card position-relative" @click="myRafflesPage">
                    <span class="d-flex align-items-center">
                        <i class="fa-thin fa-ticket icon-size"></i>

                        <h3 class="mb-0 ">
                            <v-badge right color="#FFAE05">
                                <template v-slot:badge>
                                    <span class="badgeColor">{{ activeRafflesCount }}</span>
                                </template>
                                <span class="myrafflesBadge">My Tickets</span>
                            </v-badge>
                        </h3>
                    </span>
                </div>
                <div class="menu-card" @click="go_to_mycredit">
                    <span class="d-flex align-items-center position-relative">
                        <i class="fa-light fa-coin menuCoinIcon"></i>
                        <h3 class="mb-0">My Credit</h3>
                        <h4 class="credit_value mb-0">£{{ totalCredit }}</h4>
                    </span>
                </div>

                <div class="menu-card" @click="go_to_pointstore">
                    <span class="d-flex align-items-center position-relative">
                        <i class="fa-regular fa-bag-shopping icon-size"></i>
                        <h3 class="mb-0">Store</h3>
                    </span>
                </div>

                <div class="menu-card" @click="winnersPage">
                    <span class="d-flex align-items-center">
                        <i class="fa-regular fa-trophy icon-size"></i>
                        <h3 class="mb-0">Winners</h3>
                    </span>
                </div>

                <div class="menu-card" @click="referralPage">
                    <span class="d-flex align-items-center">
                        <i class="fa-regular fa-gift icon-size"></i>
                        <h3 class="mb-0">Refer a friend</h3>
                    </span>
                </div>

                <div class="menu-card" @click="charityPage">
                    <span class="d-flex align-items-center">
                        <i class="fa-light fa-ribbon icon-size"></i>
                        <h3 class="mb-0">Charity</h3>
                    </span>
                </div>

            </div>
            <div class="menu-section">
                <h3 class="main-hdr">ACCOUNT SETTINGS</h3>
                <div class="menu-card" @click="account">
                    <span class="d-flex align-items-center">
                        <img src="https://static.raffolux.com/static/website/redev_images/personalInfoLightTheme.svg" class="icon-size personalIconLight" alt="">
                        <img src="https://static.raffolux.com/static/website/redev_images/personalDarkTheme.svg" class="icon-size personalIconDark" alt="">
                        <h3 class="mb-0">Personal info</h3>
                    </span>
                </div>

                <div class="menu-card">
                    <span class="d-flex align-items-center position-relative">
                        <i class="fa-regular fa-moon icon-size"></i>
                        <h3 class="mb-0">Light / Dark</h3>
                        <div class="toggle_btn">

                            <toggle></toggle>
                        </div>
                    </span>
                </div>
            </div>
            <div class="menu-section">
                <h3 class="main-hdr">SITE INFORMATION</h3>
                <div class="menu-card" @click="help_support">
                    <span class="d-flex align-items-center">
                        <i class="fa-regular fa-circle-question icon-size"></i>
                        <h3 class="mb-0">Help & FAQs</h3>
                    </span>
                </div>
                <div class="menu-card" @click="logout">
                    <span class="d-flex align-items-center">
                        <i class="fa-regular fa-arrow-right-from-bracket icon-size"></i>
                        <h3 class="mb-0">Logout</h3>
                    </span>
                </div>
            </div>
            <div>
                <div class="text-center tp_links">
                    <a @click="terms_conditions" class="menuSecCursor">Terms</a>
                    |
                    <a @click="privacy_policy" class="menuSecCursor">Privacy</a>
                    |
                    <a @click="help_support" class="menuSecCursor">Help</a>
                    |
                    <a @click="responsiblePlay" class="menuSecCursor">Responsible Play</a>
                </div>
            </div>
            <div class="social_media_links d-flex justify-content-center">
                <a href="https://www.facebook.com/raffolux/" class="">
                    <img src="https://static.raffolux.com/static/website/redev_images/facebookLightTheme.svg" class="withoutLoginmediaLight" alt="">
                    <img src="https://static.raffolux.com/static/website/redev_images/facebookDarkTheme.svg" class="withoutLoginmediaDark" alt="">
                </a>
                <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fraffolux%2F" class="">
                    <img src="https://static.raffolux.com/static/website/redev_images/twitterLightTheme.svg" class="withoutLoginmediaLight" alt="">
                    <img src="https://static.raffolux.com/static/website/redev_images/twitterDarkTheme.svg" class="withoutLoginmediaDark" alt="">
                </a>
                <a href="https://www.instagram.com/raffolux/" class="">
                    <img src="https://static.raffolux.com/static/website/redev_images/instagramLightTheme.svg" class="withoutLoginmediaLight" alt="">
                    <img src="https://static.raffolux.com/static/website/redev_images/instagramDarkTheme.svg" class="withoutLoginmediaDark" alt="">
                </a>
                <a href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fraffolux%2Fabout%2F" class="">
                    <img src="https://static.raffolux.com/static/website/redev_images/linkedInLightTheme.svg" class="withoutLoginmediaLight" alt="">
                    <img src="https://static.raffolux.com/static/website/redev_images/linkedInDarkTheme.svg" class="withoutLoginmediaDark" alt="">
                </a>
            </div>

        </div>
    </div>

</div>
</template>

<script>
import loader from '@/components/ReusableComponents/loader.vue'

import {
    mapGetters,
    mapActions

} from 'vuex';

import {
    apiURL
} from "@/api/allApis"

import "@/assets/css/sideBar.css";
import toggle from "../ReusableComponents/toggle.vue";
import jwt_decode from "jwt-decode";
import {
    fetch_prizeclaim_count
} from "@/mixins/getPrizeClaim.js";

import {
    urlLink,
    socialImgUrlLink
} from '@/s3bucket';
export default {
    components: {
        toggle,
        loader
    },
    mixins: [
        fetch_prizeclaim_count,
    ],

    data: () => ({
        s3Url: socialImgUrlLink + "redev_images",
        urlLink: urlLink,
        firstName: "",
        totalCredit: "",
        myCreditData: {},
        prizeClaimCount: "",
        activeRafflesCount: null,
        pointBalance: {
            balance: 0
        },
        userSession: "",
        socialMediaCheck: false,
        userId: "",
        isLoading: false,
    }),
    created() {

        this.userSession = localStorage.getItem("user_session");
        if (this.userSession) {
            this.$store.dispatch("validate_Token", this.userSession);
            this.validateToken();

            this.$store.dispatch("fetchAccountDetails", this.userSession);
            this.$store.dispatch("fetchCartItems", this.userSession);
            this.decodeToken(this.userSession);

        } else {
            localStorage.removeItem("user_session");
          
            this.$router.push({
                name: "home"
            })
        }

        this.fetchAccount();

    },

    computed: {
        ...mapGetters(["getValidateTokenData"]),

        ...mapGetters(['getAccountDetails', 'getcartCount']),
        cartCount() {
            return this.getcartCount;
        },
    },

    methods: {
        ...mapActions(['validate_Token']),
        async validateToken() {
            await this.validate_Token(this.userSession);
            if (this.getValidateTokenData) {
                this.my_credit(this.userSession);
                this.get_myRaffles(this.userSession);
                this.point_store(this.userSession);
                this.fetch_prizeclaim_count();
            } else {
                localStorage.removeItem("user_session");
                
                this.$router.push({
                    name: "home"
                })

            }
        },

        ...mapActions(['fetchAccountDetails']),
        async fetchAccount() {
            await this.fetchAccountDetails(this.userSession);
            if (this.getAccountDetails != undefined) {
                this.firstName = this.getAccountDetails[0].first_name;
            }
        },

        charityPage() {
            $(".offcanvas-backdrop").removeClass("offcanvas-backdrop");
            $("body").css("padding-right", 0);
            this.$router.push({
                name: 'charityWithLogin'
            });

        },
        cartPage() {
            this.$router.push({
                name: 'cartWithLogin'
            });
        },

        async my_credit(userSession) {
            try {
                await fetch(
                        apiURL.myCreditData, {
                            method: "POST",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                Authorization: JSON.parse(userSession),
                            },
                        }
                    )
                    .then((response) => response.json())
                    .then((data) => {
                        this.myCreditData = data;
                        localStorage.setItem(
                            "myCreditDatalocal",
                            JSON.stringify(this.myCreditData)
                        );
                        this.totalCredit = this.myCreditData.credit_wallet[0].balance;
                    });
            } catch (error) {
                this.error = error;
            }
        },

        winnersPage() {
            $(".offcanvas-backdrop").removeClass("offcanvas-backdrop");
            $("body").css("padding-right", 0);
            this.$router.push({
                name: 'winnersWithLogin'

            });

        },
        homePage() {
            $(".offcanvas-backdrop").removeClass("offcanvas-backdrop");
            $("body").css("padding-right", 0);
            this.$router.push({
                name: "homeLogin"
            })

        },

        liveCompitations() {
            this.$router.push({
                name: "homeLogin"
            })

        },

        go_to_mycredit() {
            $(".offcanvas-backdrop").removeClass("offcanvas-backdrop");
            $("body").css("padding-right", 0);

            this.$router.push("/myCredit");

        },

        go_to_pointstore() {
            $(".offcanvas-backdrop").removeClass("offcanvas-backdrop");
            $("body").css("padding-right", 0);

            this.$router.push("/pointsStore");

        },

        myRafflesPage() {
            $(".offcanvas-backdrop").removeClass("offcanvas-backdrop");
            $("body").css("padding-right", 0);

            this.$router.push("/myraffles");

        },

        account() {
            $(".offcanvas-backdrop").removeClass("offcanvas-backdrop");
            $("body").css("padding-right", 0);

            this.$router.push("/accountsPage");

        },

        referralPage() {
            $(".offcanvas-backdrop").removeClass("offcanvas-backdrop");
            $("body").css("padding-right", 0);

            this.$router.push("/referralPage");

        },

        prizeClaimPage() {
            $(".offcanvas-backdrop").removeClass("offcanvas-backdrop");
            $("body").css("padding-right", 0);

            this.$router.push("/prizeclaimSystem");

        },
        responsiblePlay() {
            $(".offcanvas-backdrop").removeClass("offcanvas-backdrop");
            $("body").css("padding-right", 0);
            this.$router.push("/play/");
        },
        privacy_policy() {
            $(".offcanvas-backdrop").removeClass("offcanvas-backdrop");
            $("body").css("padding-right", 0);
            this.$router.push("/privacy/");
        },
        terms_conditions() {
            $(".offcanvas-backdrop").removeClass("offcanvas-backdrop");
            $("body").css("padding-right", 0);
            this.$router.push("/tcs/");
        },
        help_support() {
            $(".offcanvas-backdrop").removeClass("offcanvas-backdrop");
            $("body").css("padding-right", 0);
            this.$router.push("/help/");
        },

        async get_myRaffles(userSession) {
            fetch(apiURL.myRafflesData, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        Authorization: JSON.parse(userSession),
                    },
                    body: JSON.stringify({
                        page: 1,
                    }),
                })
                .then((response) => response.json())
                .then((data) => {
                    this.myRafflesData = data;

                    this.activeRafflesCount = this.myRafflesData.raffles_active_count;
                });
        },

        async point_store(userSession) {
            fetch(
                    apiURL.pointBalance, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            Authorization: JSON.parse(userSession),
                        },
                    }
                )
                .then((response) => response.json())
                .then((data) => {
                    this.pointBalance = data;
                    localStorage.setItem(
                        "totalPoints",
                        JSON.stringify(this.pointBalance)
                    );
                });
        },

        logout() {
            localStorage.removeItem("entryValue");
            localStorage.removeItem("RaffleId");
            localStorage.removeItem("RaffleCode");
            localStorage.removeItem("addCartCheck");
            localStorage.removeItem("RaffleTitle");
            localStorage.removeItem("RaffleEntries_per_player");
            localStorage.removeItem("snackbarinstantPossitive");
            localStorage.removeItem("snackbarinstantNegative");
            localStorage.removeItem("snackbarinstantNegativeTickets");
            localStorage.removeItem("totalPoints");
            localStorage.removeItem("cartId");
            localStorage.removeItem('selectedOptionsPrizeClaim');
            localStorage.removeItem('displayPointSummaryAddress');
            localStorage.removeItem('bankId');
            localStorage.removeItem('claimPrizeDetails');
            localStorage.removeItem('pointClaimSummaryData_item_id');
            localStorage.removeItem('pointClaimSummaryData_item_type');
            localStorage.removeItem("socialMediaCheck");
            localStorage.removeItem('userId')
            localStorage.removeItem('countryCode');
            localStorage.removeItem('phoneNum');
            localStorage.removeItem('emailId');
            localStorage.removeItem('voucherID');
            localStorage.removeItem('checkoutValue');
            localStorage.removeItem('RaffDetails');
            localStorage.removeItem("myCreditDatalocal");
            localStorage.removeItem("myrafflesModel");
            localStorage.removeItem("isFP");
            localStorage.removeItem("user_session");
            localStorage.removeItem('utm_source');
            localStorage.removeItem('utm_medium');
            localStorage.removeItem('utm_campaign');
            localStorage.removeItem("is_newuser");
            localStorage.removeItem('utm_content');
            localStorage.removeItem("utm_term");
            sessionStorage.removeItem('guest_cartDetails')
            localStorage.removeItem('get_pointvalue')
            localStorage.removeItem("userDetails");
            sessionStorage.removeItem("adding_toCart");
            sessionStorage.removeItem("guestCheckoutCart");
            sessionStorage.removeItem("cartVal");
            sessionStorage.removeItem("session_cartID");
            localStorage.removeItem("raffle");
            localStorage.removeItem("cartTickets");
            localStorage.removeItem('paymentSucessGuest');
            localStorage.removeItem('cartId')
            localStorage.removeItem('dnaDetails')

            const cookieNameToClear = "user_session";
            this.clearCookie(cookieNameToClear);

            $(".offcanvas-backdrop").removeClass("offcanvas-backdrop");
            $("body").css("padding-right", 0);
            
                this.$router.push({
                    name: "home"
                }).then(() => {
                    this.$router.go(0);
                });
            

        },
        clearCookie(cookieName) {
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        },

        capitalizeFirstLetter(string) {
            if (string === null) {
                return "";
            } else {
                return string.charAt(0).toUpperCase() + string.slice(1);
            }
        },

        decodeToken(jwtToken) {
            const token = jwtToken;
            const decoded = jwt_decode(token);
            const decodedString = JSON.stringify(decoded);
            localStorage.setItem("userDetails", decodedString);
            this.userId = decoded.user_id
            localStorage.setItem("userId", this.userId)
            this.userName = decoded.user_first_name;
            this.userLastName = decoded.user_last_name;
            this.socialMediaCheck = decoded.isSocialMedia;
            localStorage.setItem("socialMediaCheck", this.socialMediaCheck);

            this.setIdentity(decodedString);

        },

        setIdentity(decodedString) {
            var userdetails = JSON.parse(decodedString)
            const identityData = {
                uid: userdetails.user_id,
                properties: {
                    displayName: userdetails.user_first_name,
                    email: userdetails.user_email,
                    custom_field: 1
                }
            };
            FS('setIdentity', identityData);

        }

    },
};
</script>
